


















import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import Form from '@/components/Forms/Form.vue';
import OfficeMap from '@/components/OfficeMap.vue';
import Awards from '@/components/Awards.vue';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';

@Component({
  components: {
    Form,
    OfficeMap,
    Awards,
  },
})
export default class SwitchToUs extends Mixins(View) {
  private office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
